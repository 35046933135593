import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { CenteredContainer } from '../components';
import gradientTop from '../images/gradient_top.jpg';

const WWWDesignPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('web-design.page-title')}</title>
      </Helmet>
      <section className="relative">
        <div
          className="absolute bottom-0 -z-10 h-full w-full bg-cover bg-right"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%), url("${gradientTop}")`,
          }}
          aria-hidden="true"
        ></div>
        <CenteredContainer className="relative">
          <div className="gap-4 md:grid md:grid-cols-2">
            <div className="order-2 md:relative">
              <StaticImage
                className="top-0 left-0 bottom-0 right-[-99999px] h-[254px] md:!absolute md:h-full"
                src="../images/web-design-page/banner_webdesign.png"
                alt=""
                aria-hidden="true"
                objectPosition="left"
                imgClassName="!object-cover md:!object-contain"
              />
            </div>
            <div className="z-10 order-1">
              <h1 className="font-secondary text-3xl font-semibold text-header md:mt-28 md:text-5xl md:leading-tight">
                {t('web-design.section-1.header')}
              </h1>
              <h4 className="mt-4 text-sm font-semibold text-header md:mt-9 md:text-2xl">
                {t('web-design.section-1.subheader')}
              </h4>
              <p className="mt-4 mb-16 text-sm md:mt-8 md:text-lg">
                <Trans t={t} i18nKey="web-design.section-1.text" />
              </p>
              <div className="mt-10 mb-9 md:mt-11 md:mb-9">
                <a className="button-primary mr-2 md:mr-4" href="#contact-form">
                  {t('shared.send-message')}
                </a>
                <a className="button-secondary" href="/#portfolio">
                  {t('shared.see-portfolio')}
                </a>
              </div>
            </div>
          </div>
        </CenteredContainer>
      </section>
      <section className="relative pt-16 pb-6 md:pb-20">
        <CenteredContainer>
          <header className="mb-9 text-center font-secondary text-2xl font-bold text-header md:mb-40 md:text-4xl">
            <h2>{t('web-design.section-2.title')}</h2>
          </header>
          <article
            className={`mt-16 grid grid-cols-1 gap-x-4 
          gap-y-4 border-b border-b-header pb-10 md:grid-cols-2 md:gap-y-0 md:pb-0 md:[grid-template-rows:min-content_100%]`}
          >
            <h4
              className={`order-1 flex flex-col items-center font-secondary text-2xl
             font-semibold text-header md:order-none md:flex-row md:text-4xl`}
            >
              <span
                className={`before:mb-6 before:mr-auto before:ml-auto before:flex before:h-[40px] before:w-[40px] before:items-center before:justify-center before:rounded-full before:bg-[color:rgb(var(--color-navy-dark))] 
                before:text-[.8em] before:text-white before:content-['1'] md:before:mr-10 md:before:mb-0 md:before:inline-flex md:before:h-[52px] md:before:w-[52px]`}
              >
                {t('web-design.section-2.items.1.title')}
              </span>
            </h4>
            <p className="order-3 mt-5 text-sm md:order-none md:text-lg md:[grid-column:1]">
              <Trans t={t} i18nKey="web-design.section-2.items.1.text" />
            </p>
            <div className="order-2 md:order-none md:[grid-row:1/span_2] md:[grid-column:2]">
              <StaticImage
                src="../images/web-design-page/get_informations.jpg"
                alt={t('web-design.section-2.items.1.title')}
              />
            </div>
          </article>
          <article className="border-b border-b-header pt-16 pb-10 md:py-24">
            <h4
              className={`order-1 flex flex-col items-center justify-center font-secondary text-2xl
             font-semibold text-header md:order-none md:flex-row md:text-4xl`}
            >
              <span
                className={`before:mb-6 before:mr-auto before:ml-auto before:flex before:h-[40px] before:w-[40px] before:items-center before:justify-center before:rounded-full before:bg-[color:rgb(var(--color-navy-dark))] 
                before:text-[.8em] before:text-white before:content-['2'] md:before:mr-10 md:before:mb-0 md:before:inline-flex md:before:h-[52px] md:before:w-[52px]`}
              >
                {t('web-design.section-2.items.2.title')}
              </span>
            </h4>
            <p className="mx-auto mt-10 max-w-4xl text-sm md:text-lg">
              <Trans t={t} i18nKey="web-design.section-2.items.2.text" />
            </p>
          </article>
          <article
            className="mt-16 grid grid-cols-1 gap-x-4 
          gap-y-4 md:grid-cols-2 md:gap-y-0 md:pt-24 md:[grid-template-rows:min-content_100%]"
          >
            <div className="order-2 mt-4 md:order-none md:mt-0 md:[grid-row:2] md:[grid-column:1]">
              <StaticImage
                src="../images/web-design-page/mockup_phone.jpg"
                alt={t('web-design.section-2.items.3.title')}
              />
            </div>
            <h4
              className={`order-1 flex flex-col items-center font-secondary text-2xl
             font-semibold text-header md:order-none md:flex-row md:text-4xl md:[grid-row:1] md:[grid-column:2]`}
            >
              <span
                className={`before:mb-6 before:mr-auto before:ml-auto before:flex before:h-[40px] before:w-[40px] before:items-center before:justify-center before:rounded-full before:bg-[color:rgb(var(--color-navy-dark))] 
                before:text-[.8em] before:text-white before:content-['3'] md:before:mr-10 md:before:mb-0 md:before:inline-flex md:before:h-[52px] md:before:w-[52px]`}
              >
                {t('web-design.section-2.items.3.title')}
              </span>
            </h4>
            <p className="order-3 mt-5 text-sm md:order-none md:text-lg md:[grid-column:2]">
              <Trans t={t} i18nKey="web-design.section-2.items.3.text" />
            </p>
          </article>
          <div className="mt-10 text-center md:mt-28">
            <a className="button-primary mr-2 md:mr-4" href="#contact-form">
              {t('web-design.section-2.fill-the-form')}
            </a>
          </div>
        </CenteredContainer>
      </section>
      <section
        className={`md:min-h-auto relative mt-[148px] flex min-h-[307px] items-end justify-center 
      bg-navy-dark pt-32 pb-11 text-white md:mt-0 md:block md:py-14`}
      >
        <CenteredContainer className="flex gap-16">
          <StaticImage
            className="!absolute top-[-108px] right-0 left-0 mx-auto w-[228px] md:!relative md:top-0 md:mx-0 md:w-[343px]"
            src="../images/balls.png"
            alt="Colorfull balls"
            aria-hidden="true"
          />
          <div className="flex flex-col items-start justify-center text-center md:text-left">
            <h3
              className="mb-6 text-lg font-bold md:mb-9 md:text-4xl"
              dangerouslySetInnerHTML={{
                __html: t('web-design.section-3.text'),
              }}
            />
            <div className="flex w-full justify-center md:block md:w-auto">
              <a className="button-primary mr-2 md:mr-4" href="#contact-form">
                {t('shared.send-message')}
              </a>
              <a className="button-secondary" href="tel:509568702">
                {t('shared.call-me')}
              </a>
            </div>
          </div>
        </CenteredContainer>
      </section>
    </>
  );
};

export default WWWDesignPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
